




































































import { debounce, debounceProcess } from "@/helpers/debounce";
import { Messages } from "@/models/enums/messages.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { RequestClosePurchaseOrder } from "@/models/interface/purchase.interface";
import { DataCalendar } from "@/models/interface/settings.interface";
import { purchaseServices } from "@/services/purchase.service";
import { settingsServices } from "@/services/settings.service";
import { Component, Vue } from "vue-property-decorator";

interface LoadingComponent {
  loadingClose: boolean;
  loadingPeriodPo: boolean;
}

@Component
export default class PurchasingClosePeriod extends Vue {
  form = this.$form.createForm(this, { name: "purchasingClosePeriod" });
  page = 1;
  limit = 10;
  params: RequestQueryParamsModel = {};
  totalData = 0;
  loading: LoadingComponent = {
    loadingClose: false,
    loadingPeriodPo: false,
  };
  dataPeriodPo: DataCalendar[] = [];

  formRules = {
    periodPo: {
      label: "lbl_period_po",
      name: "periodPo",
      placeholder: "lbl_period_po_placeholder",
      decorator: [
        "periodPo",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    status: {
      label: "lbl_status",
      name: "status",
      placeholder: "lbl_status_placeholder",
      decorator: [
        "status",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
  };

  handleChangePeriod(value) {
    let closePeriod = false;
    this.dataPeriodPo.forEach(dataForeach => {
      if (dataForeach.month === value) {
        dataForeach.calendarClosePeriods.forEach(dataForeachClose => {
          if (dataForeachClose.modulName === "PO") {
            closePeriod = dataForeachClose.closePeriod;
          }
        });
      }
    });
    this.form.setFieldsValue({
      status: !closePeriod ? "Open" : "Closed",
    });
  }

  handleBack() {
    this.$router.push("/dashboard");
  }

  onSubmit() {
    this.form.validateFields((err, res) => {
      if (err) return;
      const params: RequestQueryParamsModel = {
        period: res.periodPo,
      };
      const payload: RequestClosePurchaseOrder = {
        reasonToClose: "",
      };
      this.loading.loadingClose = true;
      purchaseServices
        .openClosePurchaseOrder(payload, params)
        .then(() => {
          this.$notification.success({
            message: "Success",
            description: `Success Close Period`,
          });
          this.getPeriodPo(res.periodPo);
          this.form.setFieldsValue({
            status: "Closed",
          });
        })
        .finally(() => (this.loading.loadingClose = false));
    });
  }

  getPeriodPo(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      modul: "PO",
    };
    if (valueSearch) params.search = `month~*${valueSearch}*`;
    settingsServices.listOfCalendar(params, "").then(response => {
      this.dataPeriodPo = response.data.filter(
        dataFilter => !dataFilter.month?.includes("Adj")
      );
    });
  }

  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  created() {
    this.getPeriodPo = debounceProcess(this.getPeriodPo, 400);
    this.getPeriodPo("");
  }

  mounted() {
    debounce(() => {
      this.form.setFieldsValue({
        status: "Open",
      });
    }, 500);
  }

  get formItemLayout() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
  }
}
